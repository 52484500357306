/* eslint-disable @typescript-eslint/no-shadow */
import { Container, Typography } from "@mui/material";
import {
  createFlickSignUpAction,
  validateAddressAction,
  validateICPAction,
} from "domain/core/actions";
import {
  clearState,
  selectAddress,
  setCreationSourceIdentifier,
  setCurrentFormStep,
  setEvChargerColor,
  setEvChargerInclusion,
  setSelectedProduct,
} from "domain/core/reducers";
import { AddressType, EvChargerColors } from "domain/core/types";
import { usePrevious } from "lib/hooks";
import { REQUEST_STATUS } from "lib/types";
import { formatAddress, resetStorage, sendAnalyticsPageView } from "lib/util";
import qs from "qs";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeHooks";
import Button from "ui/components/Button";
import { ICPExplanation } from "ui/components/ICPExplanation/ICPExplanation";
import { LoadingWrapper } from "ui/components/LoadingWrapper";
import { SingleSelectRadio } from "ui/components/SingleSelectRadio";
import { ContentWrapper, RowLayout } from "ui/components/StyledComponents";
import { AddressConfirmation } from "ui/compounds/AddressConfirmation";
import AddressInputForm, {
  ADDRESS_INPUT_TYPE,
} from "ui/compounds/AddressInputForm";
import { AddressResultDisplay } from "ui/compounds/AddressResultDisplay";
import theme, { COLORS, PADDING } from "ui/theme";

const bigTextStyle = {
  fontSize: `${27 / 16}rem`,
  fontWeight: 300,
  color: COLORS.charcoal,
  lineHeight: "1.2",
  paddingTop: PADDING.small,
  [theme.breakpoints.up("md")]: {
    fontSize: `${32 / 16}rem`,
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: `${42 / 16}rem`,
    paddingTop: PADDING.medium,
  },
};

export const Address: React.FC = () => {
  const [addressChecked, setAddressChecked] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [selectedAddress, setSelectedAddress] = useState<string>("0");
  const [firstFormInteraction, setFirstFormInteraction] =
    useState<boolean>(true);
  const [defaultEntry, setDefaultEntry] =
    useState<ADDRESS_INPUT_TYPE>("address_finder");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentLocation = useLocation();
  const pageTitle = "Let’s see if we have a power connection";

  window.onbeforeunload = () => "You have unsaved changes!";

  const {
    selectedAddress: address,
    selectedProduct,
    selectedEligibilityType,
    eligibleAddresses,
    validateAddressRequestStatus,
    rateCardsRequestStatus,
    rateCards,
  } = useAppSelector((store) => store.coreData);
  const prevStatus = usePrevious(validateAddressRequestStatus);

  useEffect(() => {
    sendAnalyticsPageView(pageTitle);
  }, []);

  useEffect(() => {
    const query = currentLocation.search;

    if (query) {
      const parsedQuery = qs.parse(query, { ignoreQueryPrefix: true });
      // if the query address matches the locally stored address then skip address validation
      // and allow the app to re-route the user to their last incomplete form step.
      if (
        parsedQuery.number === address?.number &&
        parsedQuery.postcode === address?.postcode &&
        parsedQuery.street?.toString().toLowerCase() ===
          address?.street.toLowerCase()
      ) {
        return;
      }
      if (parsedQuery.number && parsedQuery.street) {
        // Dont re-route the user based on any partially completed sign ups
        // if they've come from the estimate action on the main site.
        dispatch(setCurrentFormStep("/"));
        const newAddress: AddressType = {
          number: parsedQuery.number as string,
          street: parsedQuery.street as string,
          suburb: (parsedQuery?.suburb as string) ?? "",
          city: (parsedQuery?.city as string) ?? "",
          postcode: (parsedQuery?.postcode as string) ?? "",
          unit: (parsedQuery?.unit as string) ?? "",
          region: (parsedQuery?.region as string) ?? "",
          fullAddress: "",
        };
        dispatch(validateAddressAction.request(newAddress));
      }
      if (parsedQuery.entry === "manual") {
        setDefaultEntry("manual_address");
      }
      if (parsedQuery.creation_source === "cxagent") {
        dispatch(setCreationSourceIdentifier());
      }
    }
  }, [currentLocation, address]);

  const handleOnConfirmAddress = useCallback((address: AddressType) => {
    dispatch(validateAddressAction.request(address));
  }, []);

  const handleConfirmICP = useCallback((icp: string) => {
    dispatch(validateICPAction.request(icp));
  }, []);

  const handleCheckAnother = useCallback(() => {
    resetStorage();
    dispatch(clearState());
    navigate("/");
    setAddressChecked(false);
  }, [history]);

  const handleEnterManualICP = useCallback(() => {
    navigate("/");
    setAddressChecked(false);
    setDefaultEntry("manual_icp");
  }, [history]);

  const handleJoinNowClicked = useCallback((productSelected: string) => {
    dispatch(
      setSelectedProduct(
        productSelected === "z_ev_smart_charger" ? "z_ev" : productSelected,
      ),
    );
    dispatch(setEvChargerInclusion(productSelected === "z_ev_smart_charger"));
    dispatch(
      setEvChargerColor(
        productSelected === "z_ev_smart_charger"
          ? EvChargerColors.snow
          : undefined,
      ),
    );
    dispatch(createFlickSignUpAction.request());
    dispatch(setCurrentFormStep("/details?step=about_you"));
  }, []);

  useEffect(() => {
    if (
      prevStatus === "Pending" &&
      validateAddressRequestStatus === "Fulfilled"
    ) {
      setAddressChecked(true);
    }
    if (prevStatus === "Pending" && validateAddressRequestStatus === "Failed") {
      setError("Sorry - we can't find that ICP.");
    }
  }, [prevStatus, validateAddressRequestStatus]);

  const renderForm = useMemo(() => {
    if (addressChecked) {
      if (selectedAddress) {
        return (
          <AddressConfirmation
            address={address}
            onCheckAnother={handleCheckAnother}
            onEnterManualICP={handleEnterManualICP}
            onJoinNowClicked={handleJoinNowClicked}
            eligibility={selectedEligibilityType}
            fetchStatus={rateCardsRequestStatus}
            rateCards={rateCards}
            selectedProduct={selectedProduct}
            firstFormInteraction={firstFormInteraction}
            setFirstFormInteraction={setFirstFormInteraction}
          />
        );
      }

      if (eligibleAddresses.data.length > 1) {
        const handleOnClick = () => {
          const index = parseInt(selectedAddress);

          const nodes = eligibleAddresses.included;
          const entry = eligibleAddresses.data[index];
          const location = nodes.find(
            (x) => x.id === entry.relationships?.location_node?.data?.id,
          )?.attributes;

          const icp = entry.attributes.ea_icp_id;

          dispatch(
            selectAddress({
              eligibility: entry.attributes.eligibility,
              address: {
                icp,
                number: location?.number ?? "",
                street: location?.street ?? "",
                city: location?.city ?? "",
                suburb: location?.suburb ?? "",
                unit: location?.unit_identifier ?? "",
                fullAddress: "",
                postcode: location?.postcode ?? "",
              },
            }),
          );
        };
        const options = eligibleAddresses.data.map((supplyNode, index) => {
          const icp = supplyNode?.attributes.ea_icp_id;

          const nodes = eligibleAddresses.included;
          const entry = eligibleAddresses.data[index];
          const location = nodes.find(
            (x) => x.id === entry.relationships?.location_node?.data?.id,
          )?.attributes;

          const label = formatAddress({
            number: location?.number ?? "",
            street: location?.street ?? "",
            city: location?.city,
            suburb: location?.suburb,
            building_name: location?.building_name,
            unit: location?.unit_identifier,
            postcode: location?.postcode,
          });

          /**
           * small hack to get the current main address out to display as top result
           * since reduxState.selectedAddress is not set until an ICP is selected
           */
          const parentAddress = {
            number: location?.number ?? "",
            street: location?.street ?? "",
            city: location?.city,
            suburb: location?.suburb,
            building_name: location?.building_name,
            postcode: location?.postcode,
          };

          return {
            label,
            subLabel: `ICP: ${icp}`,
            value: index.toString(),
            isVisible: true,
            parentAddress,
          };
        });

        const multiAddressPageTitle =
          "That address has more than one connection...";
        sendAnalyticsPageView(multiAddressPageTitle);
        return (
          <Container component="main">
            <ContentWrapper>
              <Typography variant="h3">{multiAddressPageTitle}</Typography>
              <RowLayout>
                <AddressResultDisplay
                  address={options[0].parentAddress}
                  onCheckAnother={handleCheckAnother}
                />
              </RowLayout>
              <Typography variant="h5">
                Which of these is the address you want to join with?
              </Typography>
              <RowLayout>
                <SingleSelectRadio
                  selectedOptionValue={selectedAddress}
                  options={options}
                  onChangeOption={setSelectedAddress}
                />
              </RowLayout>
              <ICPExplanation />
              <Button variant="contained" onClick={handleOnClick}>
                Continue
              </Button>
            </ContentWrapper>
          </Container>
        );
      }
    }
    return (
      <>
        <Container component="main">
          <ContentWrapper>
            <Typography sx={bigTextStyle}>Kia ora,</Typography>
            <Typography variant="h3" sx={{ paddingTop: PADDING.xSmall }}>
              {pageTitle}
            </Typography>
            <AddressInputForm
              onConfirmAddress={handleOnConfirmAddress}
              onConfirmICP={handleConfirmICP}
              loading={validateAddressRequestStatus === "Pending"}
              error={error}
              defaultEntry={defaultEntry}
            />
          </ContentWrapper>
        </Container>
      </>
    );
  }, [
    addressChecked,
    handleConfirmICP,
    handleOnConfirmAddress,
    handleCheckAnother,
    handleEnterManualICP,
    handleJoinNowClicked,
    error,
    selectedAddress,
    defaultEntry,
    rateCardsRequestStatus,
  ]);

  return (
    <LoadingWrapper
      loading={validateAddressRequestStatus === REQUEST_STATUS.Pending}
    >
      {renderForm}
    </LoadingWrapper>
  );
};
